import clsx from 'clsx'
import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import React from 'react'
import { combineUrl } from '../app/data/combineUrl'
import { ContentFragment } from '../app/data/content/ContentFragment'
import { FAQFragment } from '../app/data/content/FAQFragment'
import type { FontResult } from '../app/data/content/FontFragment'
import { FooterMenuFragment } from '../app/data/content/FooterMenuFragment'
import { LinkFragment } from '../app/data/content/LinkFragment'
import { MenuFragment } from '../app/data/content/MenuFragment'
import { LocalizedSeoFragment } from '../app/data/content/SeoFragment'
import { Container } from '../components/Container'
import { Content } from '../components/Content'
import { FAQ } from '../components/FAQ'
import { Fonts } from '../components/Fonts'
import { Footer } from '../components/Footer'
import { Nav } from '../components/Nav'
import { NavCart } from '../components/NavCart'
import { Seo } from '../components/Seo'
import { StickyHeader } from '../components/StickyHeader'
import { One, Thunder } from '../generated/content'
import { serverPropsHandler } from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const content = props.dataFromContember.getLinkable?.page?.content
	const fonts = props.dataFromContember.listFont as Array<FontResult>
	const isTextual = props.dataFromContember.getLinkable?.page?.root?.textualPage
	const isStickyHeader = props.dataFromContember.getLinkable?.page?.root?.stickySidePage
	const headerSide =
		Boolean(
			props.dataFromContember.getLinkable?.page?.content?.blocks[0].references &&
				props.dataFromContember.getLinkable?.page?.content?.blocks[0].references.length > 0
		) &&
		Boolean(
			props.dataFromContember.getLinkable?.page?.content?.blocks[0].references[0].type === 'header'
		) &&
		props.dataFromContember.getLinkable?.page?.content?.blocks[0].references[0].headerView ===
			'side'
	const faqs = props.dataFromContember.listFAQ
	return (
		<div>
			<Head>
				<title>Neon Hort</title>
			</Head>
			<Seo title="Neon Hort" seo={props.dataFromContember.getLinkable?.page?.seo} />
			<Fonts fonts={fonts} />
			<Nav
				className={clsx(isTextual && 'filled', headerSide && 'filledHeader')}
				items={props.dataFromContember.getMenu?.localesByLocale}
			/>
			<NavCart />
			<div className="content">
				{isStickyHeader && content && (
					<Container className="viewStickyHeader">
						<StickyHeader
							headline={props.dataFromContember.getLinkable?.page?.headline}
							desc={props.dataFromContember.getLinkable?.page?.subHeadline}
							link={props.dataFromContember.getLinkable?.page?.button}
							email={props.dataFromContember.getLinkable?.page?.emailInput}
						/>
						<div className="contentWrapper">
							<Content textual={isTextual} content={content} />
						</div>
					</Container>
				)}
				{!isStickyHeader && content && <Content textual={isTextual} content={content} />}
				<FAQ items={faqs} />
			</div>
			<Footer
				items={props.dataFromContember.getMenu?.localesByLocale}
				footerItems={props.dataFromContember.getFooterMenu?.localesByLocale}
			/>
		</div>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const locale = urlInfo.locale

	const url = `${urlInfo.path}`

	const dataFromContember = await content.query({
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,
				page: [
					{},
					{
						title: true,
						seo: [{}, LocalizedSeoFragment],
						root: [
							{},
							{
								textualPage: true,
								stickySidePage: true,
							},
						],
						content: [{}, ContentFragment(locale)],
						button: [{}, LinkFragment()],
						emailInput: true,
						headline: true,
						subHeadline: true,
						predifinedContent: [
							{},
							{
								localesByLocale: [
									{ by: { locale: { code: locale } } },
									{
										content: [{}, ContentFragment(locale)],
									},
								],
							},
						],
					},
				],
			},
		],
		listFAQ: [{}, FAQFragment(locale)],
		getFooterMenu: [{ by: { unique: One.One } }, FooterMenuFragment(locale)],
		getMenu: [{ by: { unique: One.One } }, MenuFragment(locale)],
		listFont: [
			{},
			{
				id: true,
				langs: [
					{},
					{
						code: true,
					},
				],
				sourceFiles: [
					{},
					{
						items: [
							{},
							{
								id: true,
								file: [
									{},
									{
										type: true,
										size: true,
										url: true,
									},
								],
							},
						],
					},
				],
			},
		],
	})

	// if (!dataFromContember.getLinkable?.page) {
	// 	throw new RespondWithNotFound('Blog post not found')
	// }

	return {
		props: {
			url,
			urlInfo,
			dataFromContember,
			serverTime: new Date().toString(),
		},
	}
})
